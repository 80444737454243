.custom-calendar {
  font-family: Arial, sans-serif;
  /* width: 100%; */
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  top: 13rem;
  right: 0;
  z-index: 80;
  /* border: 2px solid green; */
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ebebeb;
  border-bottom: 1px solid #ccc;
  /* border: 2px solid yellow; */
}

.current-month {
  font-weight: bold;
  /* border: 2px solid rgb(180, 66, 66); */
}

.prev-month,
.next-month {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 20px;
  /* border: 2px solid rgb(180, 66, 180); */
}

.calendar-body {
  padding: 10px;
  /* border: 2px solid rgb(13, 160, 99); */
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
  /* border: 2px solid rgb(64, 13, 160); */
}

.calendar-weekday {
  text-align: center;
  font-weight: bold;
  /* border: 2px solid rgb(20, 5, 49); */
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  /* border: 2px solid rgb(17, 0, 5); */
}

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 6rem;
  border-radius: 10rem;
  cursor: pointer;
  /* border: 2px solid rgb(165, 14, 60); */
}

.calendar-day.empty {
  background-color: transparent;
}

.calendar-day.today {
  border: 1px solid var(--primary);
}

.calendar-day.selected {
  background-color: var(--primary);
  color: var(--white);
}
