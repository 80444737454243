.radio {
  display: none;
}

.radioContainer {
  display: flex;
  gap: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  &:hover .radioButtons {
    transform: scale(1.1);
  }
}

.radioLabel {
  cursor: pointer;
  opacity: 1;
  font-size: 2.25rem;
  margin-top: 0.7rem;
  line-height: 2.5rem;
}

.radioButtons {
  width: 4rem;
  height: 4rem;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
  }
}

.radioButtonImageContainer {
  transition: all 250ms ease;
}
