.headerContainer {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 5rem;
  /* border: 2px solid red; */
}

.headerRightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.headerBackButtonAndHeading {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  /* border: 2px solid green; */
}

.headerBackButton {
  background-color: var(--primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.headerHeadingContainer {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.headerHeadingContainer > h2 {
  text-wrap: nowrap;
  text-transform: capitalize;
}

.headerBackIcon {
  width: 2rem;
}

.headerStatus {
  background-color: var(--black);
  color: var(--white);
  opacity: 1;
  padding: 0.7rem 2.5rem;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  text-wrap: nowrap;
}

.headerRightButton {
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
}

.shareIconContainer {
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  &:active {
    transform: scale(0.95);
  }
}

.shareIconContainer > img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .headerRightContainer {
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 450px) {
  .headerContainer {
    flex-wrap: wrap;
  }

  .headerBackButtonAndHeading {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .rightChildren {
    display: flex;
    width: 100%;
  }
}
