.modalChildrenButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 2.5rem;
}

.modalChildrenHeading {
  margin-bottom: 5rem;
}

.ModalSecondaryButton:hover {
  background-color: var(--light-gray) !important;
}

.hoverButton:hover {
  background-color: aqua !important;
}

.textOverFlowDots {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
