.createBooking {
  .header {
    margin-bottom: 2.5rem;
  }

  .form {
    display: grid;
    gap: 5rem;

    .section {
      display: grid;

      .addressDropdownAndAddNewAddressButton {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        align-items: flex-end;
      }

      .heading3 {
        text-transform: capitalize;
        margin-bottom: 1.5rem;
      }

      .halfWidth {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5rem;

        .inputAndSuggestionBox {
          position: relative;

          .suggestionBox {
            position: absolute;
            z-index: 2;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            border-radius: 1rem;
            background-color: var(--white);
            left: 0;
            right: 0;
            max-height: 30rem;
            gap: 0.5rem;
            -webkit-user-select: none;
            user-select: none;
            -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            margin-top: 1rem;
            overflow-y: auto;

            >li {
              width: 100%;
              padding: 1.5rem 2rem;
              border-radius: 0.6rem;
              list-style: none;
              opacity: 1;
              cursor: pointer;
              line-height: normal;
              text-transform: capitalize;
              font-size: 2.5rem;

              &:hover {
                background-color: var(--primary-light);
                border: 0.6rem;
              }
            }
          }
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
        }
      }

      .fullWidth {
        display: grid;
        gap: 2.5rem;
      }

      .dynamicFieldsContainer {
        gap: 3.5rem;
      }
    }

    .bottomBar {
      width: -moz-fit-content;
      width: fit-content;
      position: -webkit-sticky;
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      gap: 2.5rem;
      bottom: 2.5rem;
      margin: auto;
      border-radius: 50rem;
      border: 0.5rem solid #3160d425;
      background-color: var(--primary-light);
      padding: 1.75rem 1.75rem 1.75rem 3rem;
      z-index: 9996;

      @media (max-width: 425px) {
        width: 100%;
        gap: 3.5rem;
        flex-direction: column;
        border-radius: 2.5rem;
        padding: 3.5rem 2.5rem 2.5rem 2.5rem;
      }

      .priceContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        flex-wrap: wrap;

        .price {
          font-family: AgeoBold;
          color: var(--primary);
          line-height: 100%;
        }

        .priceDescription {
          color: var(--primary);
          line-height: 100%;
        }
      }

      .bottomBarButton {
        @media (max-width: 425px) {
          min-width: 100%;
        }
      }
    }
  }
}

.numberBox {
  display: flex;
  align-items: center;

  .number {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 2rem;
    align-items: center;
    background-color: var(--white);
    color: var(--primary);
    flex-direction: column;

    p {
      font-size: 2.2rem;
      font-family: AgeoMedium;
    }
  }

  .divider {
    border-right: 0.5rem solid var(--border);
    height: 3.2rem;
    border-radius: 1rem;
    margin: 0rem 1rem;
  }
}

.BackToSaveAddress {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  transition: 0.3s;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    background-color: var(--primary-light);
    transition: 0.3s;
  }

  >p {
    line-height: normal;
    margin-top: 0.45rem;
    opacity: 1;
  }
}