.SideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40rem;
  padding: 5rem;
  background-color: var(--primary);
  position: fixed;
  height: 100dvh;
  z-index: 99999;

  .logoContainer {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;

    .logo {
      width: 15.8rem;
    }
  }

  .panelNameContainer {
    padding: 1.25rem;
    text-align: center;
    margin-bottom: 5rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.14749262536873153) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    .panelName {
      font-size: 2.2rem;
      font-weight: 500;
      color: var(--white);
      text-transform: capitalize;
      font-family: AgeoMedium;
    }
  }

  .tabsContainer {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 2rem;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 1rem;
  }

  .separator {
    width: 100%;
    height: 0.1rem;
    background-color: var(--white);
    border-radius: 10rem;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    opacity: 0.15;
  }

  /* Custom Scroll Bar */
  ::-webkit-scrollbar {
    width: 1rem;
    height: 100%;
    border-radius: 1.5rem;
    background-color: rgba(255, 255, 255, 0.25);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.75);

    border-radius: 1.5rem;
  }
  ::-webkit-scrollbar-track {
    border-radius: 1.5rem;
  }
}

/* Tab Component */
.Tab,
.Tab_active {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover .iconContainer {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 5rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.1);

    .icon {
      height: 2rem;
      width: 2rem;
    }
  }

  .name {
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--white);
    font-family: AgeoBold;
    line-height: 3.2rem;
  }
}

.Tab_active {
  background-color: var(--white);

  &:hover {
    background-color: var(--white);
  }

  &:hover .iconContainer {
    background-color: var(--primary-light);
  }

  .iconContainer {
    background-color: var(--primary-light);

    .icon {
      filter: brightness(0) saturate(100%) invert(29%) sepia(100%)
        saturate(1332%) hue-rotate(207deg) brightness(89%) contrast(87%);
    }
  }

  .name {
    color: var(--primary);
  }
}
