.light {
  --primary: #3160d4;
  --secondary: #9dffd4;
  --primary-hover: #2c56be;
  --secondary-hover: #8de5a2;
  --black: #202020;
  --offWhite: #fafafa;
  --white: #ffffff;
  --border: #3160d425;
  --border-hover: #3160d475;
  --error: #ff4d4f;
  --light-grey: #fbfbfb;
  --primary-light: #f5f7fd;
  --red: #eb4646;
  --yellow: #faad14;
  --green: #52c41a;
  --card-icon-background-color: #b7ebd4;
  --disable: #707070;
}

:root {
  --primary: #3160d4;
  --secondary: #9dffd4;
  --primary-hover: #2c56be;
  --secondary-hover: #8de5a2;
  --black: #202020;
  --offWhite: #fafafa;
  --white: #ffffff;
  --border: #3160d425;
  --border-hover: #3160d475;
  --error: #ff4d4f;
  --light-grey: #fbfbfb;
  --primary-light: #f5f7fd;
  --red: #eb4646;
  --yellow: #faad14;
  --green: #52c41a;
  --card-icon-background-color: #b7ebd4;
  --disable: #707070;

  /* --swiper-pagination-bottom: -16px; */

  /* calendar variables */

  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #ffffff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #20202015;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: var(--primary-light);
  --fc-now-indicator-color: red;
}

:root {
  --primary: #3160d4;
  --secondary: #9dffd4;
  --primary-hover: #2c56be;
  --secondary-hover: #8de5a2;
  --black: #202020;
  --offWhite: #fafafa;
  --white: #ffffff;
  --border: #3160d425;
  --border-hover: #3160d475;
  --error: #ff4d4f;
  --light-grey: #fbfbfb;
  --primary-light: #f5f7fd;
  --red: #eb4646;
  --yellow: #faad14;
  --green: #52c41a;
  --card-icon-background-color: #b7ebd4;
  --disable: #707070;

  /* calendar variables */

  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #ffffff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #20202015;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: var(--primary-light);
  --fc-now-indicator-color: red;
}

.dark {
  --primary: #3160d4;
  --secondary: #9dffd4;
  --primary-hover: #2c56be;
  --secondary-hover: #8de5a2;
  --black: #202020;
  --offWhite: #fafafa;
  --border: #3160d425;
  --border-hover: #3160d475;
  --white: #ffffff;
  --error: #ff4d4f;
  --light-grey: #fbfbfb;
  --primary-light: #f5f7fd;
  --red: #eb4646;
  --yellow: #faad14;
  --green: #52c41a;
  --card-icon-background-color: #b7ebd4;
  --disable: #707070;
}
