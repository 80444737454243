.top_bar_container {
  padding: 3.5rem 7.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 2;
  margin-bottom: 1.5rem;
  gap: 1.5rem;
}

.user_detail_container {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.avatar {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 1.5rem;
  background-color: var(--light-grey);
  object-fit: cover;
  border: 0.5rem solid var(--primary-light);
}

.notification_container {
  height: 100%;
  display: flex;
  align-items: center;
  /* gap: 2rem; */
  position: relative;
}

.notification_icon_container {
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--primary-light);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.active {
  background-color: var(--primary);
}

.notification_icon {
  width: 3rem;
  height: 3rem;
}

.notification_icon>img {
  height: 100%;
  width: 100%;
}

.topBarIcon {
  display: none;
}

.viewProfile {
  text-transform: capitalize;
  text-decoration: underline;
  color: var(--primary);
  font-weight: 500;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .notification_container {
    gap: 2rem;
  }

  .top_bar_container {
    padding: 3.5rem 3.5rem;
  }

  .topBarIconContainer {
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 50%;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .topBarIcon {
    width: 3rem;
    height: 3rem;
    display: block;
    cursor: pointer;
  }
}