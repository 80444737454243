.heading1 {
  font-family: AgeoBold;
  font-size: 5rem;
  line-height: 6rem;
  color: var(--black);
  text-transform: capitalize; 
}

.heading2 {
  font-family: AgeoSemiBold;
  font-size: 3.8rem;
  line-height: 4.6rem;
  color: var(--black);
}

.heading3 {
  font-family: AgeoMedium;
  font-size: 2.8rem;
  line-height: 4rem;
  color: var(--black);
}

.bodyText {
  font-family: AgeoMedium;
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: var(--black);
  opacity: 65%;
}

.description {
  font-family: AgeoRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: var(--black);
  opacity: 50%;
}
