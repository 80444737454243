.locationSearchInput {
  margin-bottom: 1.5rem;
  background-color: var(--white);
  outline: none;
  border: 0.5rem solid var(--border);
  border-radius: 1.5rem;
  width: 100%;
  padding: 2rem;
  font-family: AgeoMedium;
  font-size: 2.2rem;
  color: var(--black);
  transition: 0.3s;
}

.locationSearchInput::placeholder {
  color: var(--black);
  opacity: 50%;
  font-family: AgeoMedium;
}

.locationSearchInput:hover {
  border: 0.5px solid var(--border-hover);
  border: 0.5rem solid var(--border-hover);
  transition: 0.3s;
}

.locationListContainer {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
  background-color: var(--white);
  border-radius: 1.5rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.locationListItem {
  font-size: 2.5rem;
  list-style: none;
  text-decoration: none;
  background-color: var(--white);
  cursor: pointer;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
}

.locationListItem:hover {
  background-color: var(--primary-light);
}

.locationListItem ul {
  display: none;
}

.containerStyle {
  width: 100%;
  height: 400px;
  border-radius: 1.5rem;
}

.addressSuggestionContainer {
  position: relative;

  .addressSuggestionsMenu {
    background-color: var(--white);
    border: 0.25rem solid var(--off-white-hover);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    margin-top: 0.6rem;
    width: 100%;
    top: 0.5;
    z-index: 99999;
    max-height: 40rem;
    overflow-y: auto;
    -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

    .addressSuggestionsItem {
      text-align: start;
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      cursor: pointer;
      text-transform: capitalize;
      color: var(--black);
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--offWhite);
      }
    }
  }
}
