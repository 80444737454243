.DynamicRenderFieldContainer {
  width: 100%;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
}

.serviceCounterContainer {
  width: 100%;
  background-color: var(--primary-light-dark);
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.serviceFieldHeading {
  opacity: 1;
  font-family: AgeoMedium;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  width: 100%;
}

.serviceFieldHeading > span {
  margin-left: 2rem;
}

.optionalText {
  font-size: 2rem;
  font-style: italic;
  opacity: 0.5;
}

.counterContainer {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1.5rem;
  width: 17rem;
}
.counterNumber {
  font-size: 3rem;
  color: var(--black);
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.counterButton {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 1rem;

  &:hover {
    transform: scale(1.1);
  }
}

.priceCombinationContainer {
  background-color: var(--primary-light-dark);
  border-radius: 1.5rem;
}

.counterButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  border-radius: 1rem;
}

.counterButton_disabled {
  cursor: not-allowed;

  &:hover {
    transform: scale(1);
  }
}

.sectionSeparator {
  text-transform: capitalize;
}

.sectionSeparatorDiv {
  border: 0.3rem solid var(--border);
  border-radius: 100rem;
}

@media screen and (max-width: 850px) {
  .serviceCounterContainer {
    width: 100%;
  }
}
