.customDropDownValueContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 2.5rem;
  padding: 2.25rem 3rem;
  width: 100%;
  border-radius: 1.5rem;
  border: 0.5rem solid var(--border);
  background-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  background-color: #3160d410;
  color: var(--primary);
  transition: 0.3s;
  text-align: left;

  &:hover {
    border-color: var(--border-hover);
    transition: 0.3s;
  }
}

.customDropDownValueContainer:disabled {
  background-color: transparent !important;

  &:hover {
    border: 0.5rem solid var(--border) !important;
  }
}

.customDropDownMenuContainer {
  position: absolute;
  z-index: 9997;
  margin-top: 1.5rem;
  max-height: 30rem;
  background-color: var(--white);
  overflow-y: scroll;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 1.5rem;
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  left: 0;
  right: 0;
  -webkit-user-select: none;
  user-select: none;
}

.customDropDownMenuContainer::-webkit-scrollbar {
  border-radius: 1.5rem;
}

.customDropDownElement {
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  list-style: none;
  opacity: 1;
  cursor: pointer;
  line-height: normal;
  text-transform: capitalize;
  font-size: 2.5rem;
}

.customDropDownElement:hover {
  background-color: var(--primary-light);
  border: 0.6rem;
}

.customDropDownElementActive {
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  list-style: none;
  opacity: 1;
  background-color: var(--primary);
  color: var(--white);
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 2.5rem;
}

.customDropDownArrowDown {
  height: 2rem;
  width: 2rem;
  rotate: none;
  transition: 0.2s;
  filter: brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(1463%)
    hue-rotate(208deg) brightness(100%) contrast(88%);
}

.customDropDownArrowDownActive {
  height: 2rem;
  width: 2rem;
  rotate: 180deg;
  transition: 0.2s;
  filter: brightness(0) saturate(100%) invert(26%) sepia(92%) saturate(1463%)
    hue-rotate(208deg) brightness(100%) contrast(88%);
}

.customDropDownLabel {
  font-family: AgeoMedium;
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-transform: capitalize;
}

.customDropDownLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 2.5rem;
}

.placeholder {
  color: var(--primary);
  font-family: AgeoMedium;
  font-size: 2.2rem;
  opacity: 1;
  font-weight: 500;
}
.customDropDownContainer {
  width: 100%;
  position: relative;
}

.disabledDropDown {
  opacity: 0.3;
}

.optionalText {
  font-size: 2rem;
  line-height: 2rem;
  font-style: italic;
  opacity: 0.5;
}

@media screen and (max-width: 850px) {
  .customDropDownMenuContainer,
  .customDropDownValueContainer {
    width: 100%;
  }
  .customDropDownContainer {
    width: 100%;
  }
}
