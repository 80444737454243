.layoutSubContainer {
  width: calc(100% - 40rem);
  margin-left: auto;
}

.fullWidth {
  width: 100%;
}

.layoutSubMainContainer {
  margin-left: auto;
  padding: 0 7.5rem;
  padding-bottom: 7.5rem;
}

/* .layoutSubContainerHide {
  width: 100% !important;
  margin-left: auto;
  padding: 0 7.5rem;
} */

@media screen and (max-width: 768px) {
  .layoutSubMainContainer {
    margin-left: auto;
    padding: 0 3.5rem;
    padding-bottom: 3.5rem;
  }
  .layoutSubContainer {
    width: 100% !important;
    margin-left: auto;
  }
}
