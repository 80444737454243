.notificationModalContainer {
  background-color: white;
  height: 100vh;
  padding: 5rem 0rem;
  padding-bottom: 0rem;
  display: flex;
  flex-direction: column;
  width: 85rem;
  height: calc(var(--vh, 1vh) * 100);
}

.notificationModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 6rem; */
  padding: 0rem 7.5rem;
  padding-bottom: 3.5rem;
}

.notificationCloseButton {
  width: 6.5rem;
  cursor: pointer;
}

.notificationCardContainer {
  overflow: auto;
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0rem 7.5rem;
  padding-bottom: 7.5rem;
}
.notificationNotFoundScreenDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.go3958317564 {
  margin: 0rem !important;
  padding: 0rem !important;
  border-radius: 2.5rem;
}

@media screen and (max-width: 768px) {
  .notificationModalHeader {
    padding: 0rem 3.5rem;
  }
  .notificationModalContainer {
    width: 100%;
  }
  .notificationCardContainer {
    padding: 0rem 3.5rem;
    padding-bottom: 3.5rem;
  }
}

@keyframes open-modal {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
