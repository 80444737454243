.NotificationCardContainerModal {
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 2.5rem;
  background-color: var(--primary-light);
  margin-right: 1rem;
  width: calc(100% - 1rem);
}

.NotificationCardContainerPage {
  display: flex;
  /* border: 2px solid red; */
  padding: 1.5rem;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 2.5rem;
  background-color: var(--primary-light);
}

.notificationCardImageAndMessage {
  /* border: 2px solid black; */
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.notificationModal {
  /* border: 2px solid green; */
  margin-bottom: 2.5rem;
  font-family: AgeoRegular;
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #8a8b8e;
}

.notificationPage {
  max-width: 50vw;
  font-family: AgeoRegular;
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #8a8b8e;
}

.notificationCardIconImage {
  width: 7rem;
}

.notificationTime {
  opacity: 90%;
}
/* 
@media screen and (max-width: 1107px) {
  .NotificationCardContainerPage {
    flex-wrap: wrap;
  }
  .notificationTime {
    margin-left: 8.5rem;
    margin-top: 1.5rem;
  }
} */
