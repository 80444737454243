.switch {
  position: relative;
  display: inline-block;
  width: 6.7rem;
  height: 4rem;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.4s;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 10rem;
  padding: 0.8rem;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  width: 3rem;
  height: 3rem;
  background-color: #7f7f7f;
  border-radius: 10rem;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary);
  transition: 0.4s;
  padding: 0rem;
}

input:checked + .slider::before {
  transform: translateX(3rem);
  background-color: var(--white);
  transition: 0.4s;
}
