.InputContainer {
  position: relative;
}
.inputLabel {
  margin-bottom: 1rem;
  font-family: AgeoMedium;
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-transform: capitalize;
}
.label {
  font-family: AgeoMedium;
  font-size: 2.2rem;
  line-height: 3.2rem;
  text-transform: capitalize;
}
.optional {
  font-size: 2rem;
  line-height: 2rem;
  font-style: italic;
  opacity: 0.5;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 2.5rem;
}
.inputDiv {
  display: flex;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--white);
  border: 0.5rem solid var(--border);
  transition: 0.3s;
}

.inputElement {
  width: 100%;
  border: none;
  padding: 2rem;
  font-size: 2.2rem;
  outline: none;
  /* border-radius: 1.5rem; */
  color: var(--black);
  /* border: 3px solid var(--border); */
  caret-color: var(--primary);
  font-family: AgeoMedium;
  letter-spacing: 1rem;
  border-radius: 1.5rem;
}

.inputElementTextArea {
  width: 100%;
  border: none;
  padding: 3rem;
  font-size: 2.2rem;
  outline: none;
  color: var(--black);
  caret-color: var(--primary);
  font-family: AgeoMedium;
  border-radius: 1.5rem;
  border: 0.5rem solid var(--border);
  transition: 0.3s;
  height: 15rem;
}

.inputElementTextArea:hover {
  border: 0.5px solid var(--border-hover);
  border: 0.5rem solid var(--border-hover);
  transition: 0.3s;
}

.inputDiv:hover {
  border: 0.5px solid var(--border-hover);
  border: 0.5rem solid var(--border-hover);
  transition: 0.3s;
}

.inputEnd {
  position: absolute;
  right: 0;
  /* border: 2px solid green;
  width: 50%;
  height: 100%; */
}

.inputElement::placeholder {
  color: var(--black);
  opacity: 50%;
  font-family: AgeoMedium;
}

.errorMessage {
  color: var(--error);
  opacity: 100%;
  margin-top: 0.5rem;
}

.numberBox_Container {
  display: flex;
  align-items: center;
}

.phone {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  /* border: 2px solid green; */
  background-color: var(--white);
  flex-direction: column;
}

.inputStart {
  display: flex;
  align-items: center;
  justify-content: center;
}
