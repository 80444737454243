.bookingNoDataFoundContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 50vh; */
  padding-top: 10rem;
  border-radius: 2.5rem;
}

.bookingNoDataFoundImageContainer {
  margin-bottom: 2.5rem;
}

.bookingNoDataFoundHeading {
  margin-bottom: 1rem;
}

.bookingNoDataFoundSubContainer {
  text-align: center;
}

.NotFoundButtonContainer {
  margin-top: 5rem;
}
