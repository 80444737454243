@font-face {
  font-family: "AgeoBold";
  src: url("../assets/fonts/Ageo-Bold.otf") format("opentype");
}

@font-face {
  font-family: "AgeoExtraBold";
  src: url("../assets/fonts/Ageo-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "AgeoHeavy";
  src: url("../assets/fonts/Ageo-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "AgeoLight";
  src: url("../assets/fonts/Ageo-Light.otf") format("opentype");
}

@font-face {
  font-family: "AgeoMedium";
  src: url("../assets/fonts/Ageo-Medium.otf") format("opentype");
}

@font-face {
  font-family: "AgeoSemiBold";
  src: url("../assets/fonts/Ageo-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "AgeoThin";
  src: url("../assets/fonts/Ageo-Thin.otf") format("opentype");
}

@font-face {
  font-family: "AgeoRegular";
  src: url("../assets/fonts/Ageo.otf") format("opentype");
}
