.checkBoxInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.checkBoxLabel {
  background: none;
  border: none;
  color: var(--black);
  font-size: 2.5rem;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-start;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.3s;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  opacity: 1;

  .CheckboxIcons {
    width: 2.5rem;
    height: 2.5rem;
  }
}