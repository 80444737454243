.CustomButton {
  width: 100%;
  padding: 2.7rem;
  cursor: pointer;
  font-family: AgeoSemiBold;
  line-height: 2.8rem;
  font-size: 2.8rem;
  border-radius: 1.5rem;
  border: none;
  background-color: var(--primary);
  color: var(--primary);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomButton:hover {
  transition: 0.3s;
  /* background-color: var(--secondary-hover); */
  transform: scale(1.015);
  /* scale: 1.015; */
}

button:disabled {
  background-color: rgba(63, 63, 63, 0.404) !important;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.219) !important;
}
button:disabled:hover {
  background-color: rgba(63, 63, 63, 0.404);
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.219);
  transform: scale(1);
}

/* secondary button css start */

.SecondaryCustomButton {
  width: 100%;
  border: none;
  background-color: var(--primary);
  color: var(--white);
  padding: 1.5rem 3rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: 0.3s;
  text-transform: capitalize;
}

.SecondaryCustomButton:hover {
  background-color: var(--primary-hover);
  transition: 0.3s;
}

/* .TertiaryButton css */

.TertiaryButton {
  width: 100%;
  border: none;
  background-color: var(--white);
  color: var(--primary);
  padding: 1.5rem 3rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.TertiaryButton:hover {
  background-color: #fbfbfb;
  transition: 0.3s;
}

/* iconButton */

.IconButton {
  border: none;
  background-color: var(--white);
  color: var(--primary);
  padding: 1.5rem 3rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.IconButton:hover {
  background-color: #fbfbfb;
  transition: 0.3s;
}
