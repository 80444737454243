.splashScreenContainer {
  display: flex;
  width: 100%;
  height: 100dvh;
  background-color: var(--primary);
  /* border: 2px solid green; */
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.3s ease;
}

@media screen and (max-width: 650px) {
  .splashScreenContainer > img {
    height: 20rem;
  }
}
