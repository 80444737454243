.modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: modal-open 0.2s ease-out;
  backdrop-filter: blur(5px) saturate(100%);
  -webkit-backdrop-filter: blur(5px) saturate(100%);
  background-color: rgba(0, 0, 0, 0.35);
}
.modal {
  min-width: 100rem;
  position: fixed;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  border-radius: 5rem;
  padding: 5rem;
  animation: modal-open 0.2s ease-out;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.8rem;
}

.modalCloseButton {
  cursor: pointer;
  width: 4.5rem;
}

.modalSeparateDiv {
  border-bottom: 3px solid var(--primary);
  opacity: 5%;
  margin-bottom: 3.5rem;
}

body.modal-open {
  overflow: hidden;
}

@keyframes modal-open {
  from {
    opacity: 0;
    /* transform: scale(0.8); */
  }
  to {
    opacity: 1;
    /* transform: scale(1); */
  }
}

@media screen and (max-width: 550px) {
  .modal {
    min-width: 94%;
  }
}
